<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @downloadFile="onDownloadFile"
    @save="onSave('OneCPinCodes')"
    @cancel="onCancel('OneCPinCodes')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'OneCPinCodeCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'OneCPinCodes',
      pageHeader: 'Создание пин-кода 1с',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Пин-коды 1с',
          route: { name: 'OneCPinCodes' },
        },
        {
          text: 'Создание пин-кода 1с',
        },
      ],
      initialForm: {
        regNumber: '',
        type: '',
        currentPinCode: '',
        serverId: '',
        unusedPinCodes: '',
        isInstalled: false,
        licenseFile: null,
        licenseDataFile: null,
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      servers: (state) => state.common.servers,
    }),
    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'regNumber',
              label: 'Рег.номер',
            },
            {
              type: 'text',
              key: 'type',
              label: 'Тип',
            },
            {
              type: 'select',
              key: 'serverId',
              label: 'Сервер',
              list: this.servers.data,
            },
            {
              type: 'text',
              key: 'currentPinCode',
              label: 'Текущий пин-код',
            },
            {
              type: 'text',
              key: 'unusedPinCodes',
              label: 'Не использованные пин-коды',
            },
            {
              type: 'file',
              key: 'licenseFile',
              label: 'Файл лицензии',
            },
            {
              type: 'file',
              key: 'licenseDataFile',
              label: 'Файл лицензионных данных',
            },
            {
              type: 'checkbox',
              key: 'isInstalled',
              label: 'Установлено',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    this.getCommonList({ name: 'Servers' }).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
      downloadFile: 'editableList/downloadFile',
    }),

    validateForm(form, mandatoryFields) {
      const emptyField = mandatoryFields.filter((item) => {
        return !form[item.key];
      });
      let message = null;
      if (emptyField.length) {
        message = `Не заполнены обязательные поля: ${emptyField
          .map((item) => item.label)
          .join(', ')}`;
      }
      return message;
    },

    onSave(route) {
      const mandatoryFields = [{ key: 'regNumber', label: 'Рег.номер' }];

      const validationError = this.validateForm(this.form, mandatoryFields);
      if (validationError) {
        this.showNotification(validationError, 'error');
        return;
      }

      this.processSave(route);
    },

    showNotification(header, type) {
      this.$notify({
        header,
        type,
        timer: 5000,
      });
    },

    async processSave(route) {
      this.isLoadingPage = true;
      try {
        const result = await this.createItem({
          name: this.controllerName,
          payload: this.form,
        });
        if (result.isSucceed) {
          this.$router.push({ name: route });
        }
      } finally {
        this.isLoadingPage = false;
      }
    },

    onDownloadFile({ key }) {
      const fileType = key === 'licenseDataFile' ? 'data' : 'file';
      
      this.downloadFile({
        name: this.controllerName,
        id: this.id,
        type: fileType,
      });
    },
  },
};
</script>
